<template>
  <div class="track" :class="{skeleton: !state.loaded}">
    <div class="wrapper">
      <div class="title font-md">
        <b>UTM 트래킹</b>
        <span class="ml-1 font-xs">(총 {{ $lib.getNumberFormat(state.count) }}건)</span>
      </div>
      <div class="desc font-sm">
        <b>{{ modalParams.memberName }}</b>
        <span>님의 상세 트래킹 내용입니다.</span>
      </div>
      <div class="side">
        <span class="stay" title="체류 시간">⏱️ {{ $lib.getTimeFormat(modalParams.staySeconds) }}</span>
        <div class="dates" title="체류 기간">{{ computedDuration }}</div>
      </div>
      <div class="line">
        <ul class="tight clearfix">
          <li v-for="(t1, idx1) in state.tracks" :key="idx1">
            <div class="row">
              <div class="interaction col-lg-4" :data-step="t2.step" :data-tag="t2.tag" :class="{ 'no-before': t2.tag === 'END' && (state.count + 2) % 6 !== 0 }" v-for="(t2, idx2) in t1" :key="idx2">
                <div class="inner" :class="{'bg-secondary': !t2.tag}">
                  <div class="top">
                    <b class="step">{{ t2.step > 0 ? "STEP " + t2.step : t2.tag }}</b>
                    <div class="float-right" title="생성일시">{{ t2.createDate }}</div>
                  </div>
                  <div class="texts clearfix">
                    <div class="float-left">
                      <span class="emoji" v-if="getEmoji(t2)">{{ getEmoji(t2) }}</span>
                      <span class="text">
                        <template>{{ t2.category }}</template>
                        <template v-if="t2.topic"> - {{ t2.topic }}</template>
                      </span>
                    </div>
                    <div class="right float-right font-xs">
                      <template v-if="t2.step === '0'">
                        <span class="os" title="운영체제" v-if="t2.os">{{ t2.os }}</span>
                        <span class="browser" title="브라우저" v-if="t2.browser">{{ t2.browser }}</span>
                      </template>
                      <span title="메모" v-else-if="t2.memo">{{ t2.memo }}</span>
                    </div>
                  </div>
                  <div class="bottom">
                    <div v-if="t2.type === 'page' && t2.httpMethod === 'GET' && t2.urlPath">
                      <a class="pointer-u" :href="t2.urlPath + t2.urlQuery" target="_blank" rel="noopener noreferrer">
                        <span class="ellipsis title" :title="t2.title" v-if="t2.title">{{ t2.title }}</span>
                        <span v-else>해당 페이지로 이동</span>
                      </a>
                    </div>
                    <template v-else-if="t2.step === '0'">
                      <div class="ellipsis" v-html="computedUtmHtml" v-if="state.loaded"></div>
                      <span v-else>Wait a moment</span>
                    </template>
                    <div class="ellipsis" :title="t2.title" v-else-if="t2.title">{{ t2.title }}</div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, onMounted, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import http from "@/scripts/http";

function Component(initialize) {
  this.name = "modalTrack";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      for (let i = 0; i < 3; i += 1) {
        state.tracks.push([]);

        for (let j = 0; j < trackCntPerArr; j += 1) {
          state.tracks[i].push({
            step: "0",
            category: "Wait a ",
            topic: "moment",
            title: "Please wait a moment.",
          });
        }
      }

      state.loaded = false;
      http.get(`/api/admin/stats/tracks/${modalParams.chainCode}`).then(({data}) => {
        state.loaded = true;

        const tracks = data.body;
        state.count = tracks.length;
        state.tracks = [];

        tracks.splice(0, 0, {
          step: "0",
          category: "트래킹 시작",
          os: tracks[0].os,
          browser: tracks[0].browser,
          createDate: tracks[0].createDate,
          tag: "START"
        });

        tracks.push({
          step: "0",
          category: "트래킹 종료",
          os: tracks[tracks.length - 1].os,
          browser: tracks[tracks.length - 1].browser,
          createDate: tracks[tracks.length - 1].createDate,
          tag: "END"
        });

        const limit = Math.ceil(tracks.length / trackCntPerArr);

        for (let i = 0; i < limit; i += 1) {
          state.tracks.push([]);

          for (let j = 0; j < trackCntPerArr; j += 1) {
            const idx = i * trackCntPerArr + j;
            const obj = tracks[idx];

            if (tracks[idx]) {
              if (!obj.step) {
                obj.step = idx;
              }

              state.tracks[i].push(obj);
            }
          }
        }

        const additionalLen = trackCntPerArr - state.tracks[state.tracks.length - 1].length;

        for (let i = 0; i < additionalLen; i += 1) {
          state.tracks[state.tracks.length - 1].push({});
        }

        for (let i = 1; i < state.tracks.length; i += 2) {
          state.tracks[i] && state.tracks[i].reverse();
        }
      });
    });

    const state = reactive({
      loaded: false,
      count: 0,
      tracks: [],
    });

    const modalParams = store.getters.modalParams(component);
    const trackCntPerArr = 3;

    const computedUtmHtml = computed(() => {
      const arr = [];

      if (state.tracks.length > 1) {
        state.tracks[0][1].utmSource && arr.push(`<span title="UTM Soruce">${state.tracks[0][1].utmSource}</span>`);
        state.tracks[0][1].utmMedium && arr.push(...[`<i class="fa fa-angle-right ml-1 mr-1"></i>`, `<span title="UTM Medium">${state.tracks[0][1].utmMedium}</span>`]);
        state.tracks[0][1].utmCampaign && arr.push(...[`<i class="fa fa-angle-right ml-1 mr-1"></i>`, `<span title="UTM Campaign">${state.tracks[0][1].utmCampaign}</span>`]);
      }

      return arr.join("");
    });

    const getEmoji = (track) => {
      if (track.step === "0") {
        return "🏁";
      }

      if (track.type === "api" && track.httpMethod === "POST") {
        switch (track.name) {
          case "memberJoin":
            return "🎉";

          case "memberLogin":
            return "😃";

          case "myPageInvestorApply":
            return "📝";

          case "rewardProjectPay":
          case "investProjectSubscribe":
            return "❤️";
        }
      }
    };

    const computedDuration = computed(() => {
      let firstDate = modalParams.firstDate;
      let lastDate = modalParams.lastDate;

      if (firstDate === lastDate) {
        return firstDate;
      }

      for (let i = 17; i > 0; i -= 3) {
        if (lastDate.startsWith(firstDate.substr(0, i))) {
          lastDate = lastDate.replace(firstDate.substr(0, i), "");
          break;
        }
      }

      return [firstDate, " ~ ", lastDate].join("");
    });

    onMounted(() => {
      store.commit("tuneModal", {component, size: "lg"});
    });

    return {component, state, modalParams, trackCntPerArr, computedUtmHtml, getEmoji, computedDuration};
  }
});
</script>

<style lang="scss" scoped>
.track {
  background: #fff;
  padding: $px25;

  > .wrapper {
    > .title {
      margin-bottom: $px9;
    }

    > .desc {
      margin-bottom: $px25;
      color: #666;
    }

    position: relative;

    > .side {
      position: absolute;
      top: 0;
      right: 0;
      text-align: right;
      padding-top: $px11;

      .stay {
        font-size: $px14;
      }

      .dates {
        padding-top: $px5;
        font-size: $px12;
      }
    }

    .line {
      ul {
        > li {
          min-width: $px100;
          font-size: $px13;
          padding-bottom: $px50;
          position: relative;
          //text-align: center;

          .interaction {
            position: relative;

            .inner {
              border: $px1 solid #eee;
              padding: $px20;
              min-height: $px106;
              border-radius: $px4;

              .top {
                font-size: $px10;
                padding-bottom: $px5;

                span, i {
                  vertical-align: baseline;
                }

                i {
                  margin: 0 $px5;
                }
              }

              .texts {
                span, a {
                  vertical-align: middle;
                }

                span {
                  &.emoji {
                    margin-right: $px5;
                  }
                }

                .right {
                  opacity: 0.9;
                  padding-top: $px1;
                  font-weight: 300;

                  span {
                    margin-left: $px5;

                    &.os, &.browser {
                      letter-spacing: $px-1;
                    }
                  }
                }
              }

              .bottom {
                font-size: $px12;
                padding-top: $px6;

                a {
                  opacity: 0.9;

                  > .title {
                    display: block;
                  }
                }
              }
            }

            &:not([data-step]) {
              visibility: hidden;
            }

            &[data-step="0"] .inner {
              background: $colorBackground;
            }

            &:not(:nth-child(3n+0)) {
              &:before {
                content: " ";
                display: block;
                border-top: $px1 solid #ddd;
                position: absolute;
                top: 50%;
                right: 0;
                width: $px30;
                transform: translateX(50%);
              }
            }
          }

          &:nth-child(odd) .interaction:nth-child(3n+0):after,
          &:nth-child(even) .interaction:nth-child(1):after {
            content: " ";
            display: block;
            border-left: $px1 solid #ddd;
            position: absolute;
            top: 100%;
            left: 50%;
            height: 100%;
          }

          &:last-child {
            padding-bottom: 0;

            .interaction:after {
              content: none !important;
            }
          }
        }
      }
    }
  }

  &.skeleton {
    > .wrapper .line ul > li {
      .interaction {
        .inner {
          .top {
            b, span {
              @include skeleton;
            }
          }

          span {
            &.emoji {
              @include skeleton;
            }

            &.text {
              @include skeleton;
            }
          }

          .title > span {
            @include skeleton;
          }

          .bottom > span {
            @include skeleton;
          }
        }

        > i {
          visibility: hidden;
        }
      }
    }
  }

  @media(max-width: 991px) {
    > .wrapper .line ul > li {
      padding-bottom: 0;

      .interaction {
        padding-bottom: $px15;

        .inner {
          min-height: auto;
        }

        &:before, &:after {
          content: none !important;
        }
      }
    }
  }
}
</style>